<template>
    <el-card :style="{ marginBottom: '20px' }">
        
        <!-- Header -->
        <div slot="header">
            <span>
                <i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                关联附件
            </span>
        </div>

        <!-- Empty -->
        <el-empty description="暂无附件" v-if="!table.length"></el-empty>

        <!-- 卡片列表 -->
        <div class="c-files-grid">
            <div class="c-files-grid-item" v-for="(v, k) in table" :key="k">
                
                <img class="c-files-grid-img"
                    v-if="['png', 'jpeg'].includes(v.format)"
                    :src="'/9api/components-files/preview?id=' + v.id + '&path=' +  v.path" />

                <img class="c-files-grid-format"
                    v-else
                    :src="'/img/files/' + v.format + '.png'" />
                
                <div class="c-files-grid-title">{{ v.name }}</div>
                <div class="c-files-grid-buttons">
                    <a @click="filesPreview(v)"><i class="el-icon-search"></i></a>
                    <a @click="filesDown(v)"><i class="el-icon-download"></i></a>
                    <a @click="filesDelete(v)"><i class="el-icon-delete"></i></a>
                </div>
                <div class="c-files-grid-footer">
                    <img class="c-files-grid-avatar" :src="v.users?.avatar" />
                    <div>
                        <div>{{ v.created_at }}</div>
                        <div>{{ v.users?.name }}</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 预览 -->
        <el-dialog
            class="w-el-dialog"
            :visible.sync="previewVisible"
            fullscreen
            :title="previewFiles.name"
            append-to-body
            :close-on-click-modal="false" >
            <iframe class="c-iframe" :src="previewUrl" frameborder="no"></iframe>
        </el-dialog>

        <!-- 下载 -->
        <iframe :src="downUrl" width="0" height="0" v-show="false"></iframe>

    </el-card>
</template>

<script>
export default {
    props: {
        files_id: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            loading: false,

            table: [],
            
            previewVisible: false,
            previewUrl: null,
            previewFiles: {},

            downUrl: null
        }
    },

    watch: {
        'files_id': {
            handler(val) {
                val.length ? this.tableSearch() : this.table = []
            },
            immediate: true
        }
    },

    methods: {
        tableSearch() {
            this.loading = true
            this.$http.post('/9api/pm/projects-files/search-all', { search: { ids: this.files_id }, append: ['users'] }).then((rps) => {
                this.table = rps.data.files || []
                this.loading = false
            })
        },

        filesPreview(files) {
            // Office文件预览
            if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv' ].includes(files.format)) {
                this.previewVisible = true
                this.previewUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent('https://aqyy.waner.cn/9api/components-files/preview?id=' + files.id + '&path=' +  files.path)
            } else {
                this.previewVisible = true
                this.previewUrl = '/9api/components-files/preview?id=' + files.id + '&path=' +  files.path
            }
            this.previewFiles = files
        },

        filesDown(files) {
            this.downUrl = '/9api/components-files/down?id=' + files.id + '&path=' +  files.path
        },

        filesDelete(files) {
            this.$http.post('/9api/components-files/delete', { id: files.id }).then(rps => {
                this.tableSearch()
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .c-files-grid { display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px; }
    .c-files-grid-item { cursor: pointer; width: 100%; padding-bottom: 100%; position: relative; overflow: hidden; }
    .c-files-grid-item { transition: 300ms; border: 1px solid #eeeeee; background: #444444; border-radius: 5px; }
    .c-files-grid-img { transition: 300ms; position: absolute; object-fit: contain; width: 100%; height: 100%; }
    .c-files-grid-format { transition: 300ms; position: absolute; object-fit: contain; width: 100%; height: 100%; padding: 30%; box-sizing: border-box; }
    .c-files-grid-title { position: absolute; top: 0; left: 0; width: 100%; box-sizing: border-box; padding: 10px 10px 20px 10px; }
    .c-files-grid-title { color: #FFFFFF; font-size: 12px; }
    .c-files-grid-title { background: linear-gradient(to bottom, #000000, #FFFFFF00); }
    .c-files-grid-title { text-shadow: 0px 0px 24px #000000;}
    .c-files-grid-footer { position: absolute; bottom: 0; left: 0; width: 100%; box-sizing: border-box; padding: 10px; }
    .c-files-grid-footer { display: flex; align-items: center; }
    .c-files-grid-footer { background: #000000bf; }
    .c-files-grid-footer { color: #FFFFFF; font-size: 12px;}
    .c-files-grid-avatar { margin-right: 10px; height: 32px; width: 32px; border-radius: 16px; border: 1px solid #FFFFFF; }
    .c-files-grid-buttons { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
    .c-files-grid-buttons { transition: 300ms; display: flex; align-items: center; justify-content: center; opacity: 0; }
    .c-files-grid-buttons a { transition: 300ms; margin: 0 5px; color: #FFFFFF; background: #00000080; border-radius: 20px; }
    .c-files-grid-buttons a { display: block; width: 40px; height: 40px; line-height: 40px; text-align: center; font-size: 22px; }
    .c-files-grid-item:hover { box-shadow: 0px 0px 10px 0px #0027ff; }
    .c-files-grid-item:hover .c-files-grid-img { width: 110%; }
    .c-files-grid-item:hover .c-files-grid-buttons { opacity: 1; }
    .c-files-grid-buttons a:hover { background: #2196f3cc; }
    .c-iframe { display: block; width: 100%; height: 100%; }
</style>