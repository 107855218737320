<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务详情
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer
        ref="elDrawer"
        :visible.sync="visible"
        size="960px"
        direction="rtl"
        append-to-body
        destroy-on-close
        :show-close="false"
        :before-close="Close">

        <!-- header -->
        <div slot="title">

            <div class="w-margin-b-10">
                <span>任务</span>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" type="primary">ID: {{ task.id }}</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" type="primary">类型: {{ task.type }}</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 1" type="danger">P1 非常重要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 2" type="warning">P2 重要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 3" type="primary">P3 次要</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.p == 4" type="success">P4 一般</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 0" type="primary">未开始</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 1" type="primary">进行中</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 2" type="warning">已暂停</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 4" type="info">已关闭</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 5" type="success">已完成</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.status == 6" type="info">已取消</el-tag>
                <el-tag  style="margin-left: 5px;" size="small" effect="plain" v-if="task.is_overdue == 1" type="danger">已延期</el-tag>
            </div>
            <span class="el-span-primary" :style="{ fontSize: '18px', marginBottom: '10px',fontWeight: 'bold', display: 'block' }" @click="$refs.projectsDrawer.Open(task.project.id)">{{ task.project.number }}-{{ task.project.name }}</span>
            <div class="w-margin-b-10" :style="{ fontSize: '18px', color: task.name_color, fontWeight: 'bold' }">{{ task.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                
                <!-- 任务流程 -->
                <el-button-group>
                    <el-button type="primary" :disabled="[1, 4].includes(task.status)" @click="$refs.taskStart.Open(task)">开 始</el-button>
                    <el-button type="primary" :disabled="[4].includes(task.status)" @click="$refs.taskChange.Open(task)">转 交</el-button>
                    <el-button type="primary" :disabled="[0, 2, 4, 5, 6].includes(task.status)" @click="$refs.taskComplete.Open(task)">完 成</el-button>
                    <el-button type="primary" :disabled="[0, 1, 2, 4, 6].includes(task.status)" @click="$refs.taskClose.Open(task)">关 闭</el-button>
                </el-button-group>

                <!-- 任务流程 -->
                <el-button-group class="w-margin-l-10">
                    <el-button type="warning" :disabled="[2, 4, 5, 6].includes(task.status)" @click="$refs.taskSuspend.Open(task)">暂 停</el-button>
                    <el-button type="warning" :disabled="[6].includes(task.status)" @click="$refs.taskCancel.Open(task)">取 消</el-button>
                </el-button-group>

                <el-button-group class="w-margin-l-10">
                    <el-button type="warning" :disabled="task.status == 4"  @click="$refs.taskEdit.Open(task.id)">编 辑</el-button>
                    <el-button type="danger"  @click="TaskDelete([task.id])">移 除</el-button>
                </el-button-group>
            </div>

        </div>

        <div :style="{ padding: '20px' }" v-if="!loading">

            <!-- 基本信息 -->
            <div :style="{ marginBottom: '20px' }" style="background: #E8EAF6; border-radius: 3px; padding: 15px; display: flex; border: 1px solid #0027ff;">
                <el-statistic title="指派给"><template slot="formatter"><span style="font-size: 16px;"><b>{{ task.now_assign_user_name }}</b></span></template></el-statistic>
                <el-statistic title="任务预期/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.day_predict }}</span></template></el-statistic>
                <el-statistic title="任务消耗/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.day_consume }}</span></template></el-statistic>
                <el-statistic title="工单消耗/P"><template slot="formatter"><span style="font-size: 14px;">{{ task.wo_sum_day_consume }}</span></template></el-statistic>
                
                <el-statistic title="小计/P"><template slot="formatter"><span style="font-size: 14px;">{{ Number(task.day_consume) + Number(task.wo_sum_day_consume) }}</span></template></el-statistic>
            </div>


            <div :style="{ display: 'flex' }">

                <!-- Left -->
                <div :style="{ flex: '1 0 0', width: '0' }">

                    <!-- 任务描述 -->
                    <pm-task-drawer-card-describe
                        :style="{ marginBottom: '20px' }"
                        v-model="task.describe" />

                    <!-- 任务流程 -->
                    <pm-task-drawer-card-flow
                        :style="{ marginBottom: '20px' }"
                        :task_id="task.id" />

                    <!-- 关联工单 -->
                    <pm-task-drawer-card-wo
                        :style="{ marginBottom: '20px' }"
                        :projects_id="task.project_id"
                        :task_id="task.id" />

                    <!-- 任务附件 -->
                    <pm-task-drawer-card-files
                        :style="{ marginBottom: '20px' }"
                        :files_id="task.files_id" />
                        
                </div>

                <!-- Right -->
                <div :style="{ paddingLeft: '20px', width: '250px' }">
                    
                    <!-- 任务概况 -->
                    <el-card :style="{ marginBottom: '20px' }">
                        <div slot="header">
                            <span>
                                <i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                                参与成员
                            </span>
                        </div>
                        <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }">
                            <div v-for="(item, idx) in task.assign_user_object" :key="idx" style="display: flex; flex-direction: column; align-items: center;">
                                <el-avatar size="medium" :src="item.avatar">
                                    {{ item.name[0] }}
                                </el-avatar>
                                <el-link :underline="false" :type="task.now_assign == item.id ? 'primary' : 'warning'" style="margin-top: 5px;">{{ item.name }}</el-link>
                            </div>
                        </div>
                    </el-card>

                    <!-- 工时信息 -->
                    <pm-task-drawer-card-day
                        :style="{ marginBottom: '20px' }"
                        :task_id="task.id"
                        @onChange="OnChange" />

                    <!-- 任务概况 -->
                    <el-card :style="{ marginBottom: '20px' }">
                        <div slot="header">
                            <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>任务概况</span>
                        </div>
                        <el-descriptions :column="1" >
                            <el-descriptions-item label="预计开始日期">{{ task.start_date[0] }}</el-descriptions-item>
                            <el-descriptions-item label="预计结束日期">{{ task.start_date[1] }}</el-descriptions-item>
                            <el-descriptions-item label="创建人">{{ task.create_user_name }}</el-descriptions-item>
                            <el-descriptions-item label="创建时间">{{ task.created_at }}</el-descriptions-item>
                            <el-descriptions-item label="更新人">{{ task.update_user_name }}</el-descriptions-item>
                            <el-descriptions-item label="更新时间">{{ task.updated_at }}</el-descriptions-item>
                            <el-descriptions-item label="完成人">{{ task.finish_users?.name }}</el-descriptions-item>
                            <el-descriptions-item label="完成时间">{{ task.finish_at }}</el-descriptions-item>
                            <el-descriptions-item label="关闭人">{{ task.close_user_name }}</el-descriptions-item>
                            <el-descriptions-item label="关闭时间">{{ task.close_date }}</el-descriptions-item>
                            <el-descriptions-item label="取消人">{{ task.cancel_user_name }}</el-descriptions-item>
                            <el-descriptions-item label="取消时间">{{ task.cancel_date }}</el-descriptions-item>
                            <el-descriptions-item label="开始时间" :span="3">{{ task.actual_start_date }}</el-descriptions-item>
                            <el-descriptions-item label="分配时间" :span="3">{{ task.assign_date }}</el-descriptions-item>
                        </el-descriptions>
                    </el-card>

                </div>

            </div>

        </div>

        <!-- 关联项目 -->
        <pm-projects-drawer ref="projectsDrawer" @onChange="OnChange" />
        <pm-task-update ref="taskEdit" @onSave="OnChange" />

        <!-- 任务 - 开始/转交/完成/关闭 -->
        <taskStart ref="taskStart" @onSave='OnChange'></taskStart>
        <pm-task-flow-change ref="taskChange" @onSave='OnChange' />
        <pm-task-flow-complete ref="taskComplete" @onSave='OnChange' />
        <taskClose ref="taskClose" @onSave='OnChange'></taskClose>

        <!-- 任务 - 取消/暂停/移除 -->
        <taskCancel ref="taskCancel" @onSave='OnChange'></taskCancel>
        <taskSuspend ref="taskSuspend" @onSave='OnChange'></taskSuspend>
        
    </el-drawer>
</template>

<script>
import taskStart from '@/views/projects/drawerProjectsTaskFlowStart'
import taskClose from '@/views/projects/drawerProjectsTaskFlowClose'
import taskCancel from '@/views/projects/drawerProjectsTaskFlowCancel'
import taskSuspend from '@/views/projects/drawerProjectsTaskFlowSuspend'

export default {

    // 组件
    components: {
        taskStart, taskClose, taskCancel, taskSuspend,
    },

    // 存储数据
    data () {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 任务信息
            id: null,
            task: {
                project: {},
                start_date: [],
                files_id: []
            },
            
        }
    },

    // 方法定义
    methods: {
        
        // 窗口 - 打开
        async Open(id) {
            this.id = id
            this.visible = true
            this.TaskGet()
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 窗口 - 更新
        |--------------------------------------------------
        |
        */
        
        OnChange() {
            this.TaskGet()
            this.$emit('onChange')
        },


        /**
        |--------------------------------------------------
        | 任务 - 切换
        |--------------------------------------------------
        |
        */

        TaskChange(id) {
            this.id = id
            this.TaskGet()
        },

        
        /**
        |--------------------------------------------------
        | 任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {
            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },

        // 任务 - 详情
        async TaskGet () {
            this.loading = true
            this.$http.post(
                '/9api/projects-task/search-id',
                {
                    id: this.id, 
                    append: ['project', 'finish_users', 'wo_sum_day_consume', 'now_assign_user_name', 'type', 'assign_user_object', 'create_user_name', 'update_user_name', 'close_user_name', 'cancel_user_name']
                }
            ).then(rps => {
                this.task = rps.data
                this.loading = false
            })
        },
    }
}
</script>