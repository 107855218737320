import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

/**
|--------------------------------------------------
| 设置路由表
|-------------------------------------------------- 
|
*/

const routes = [
    // 登入界面
    { path: '/auth/login', component: () => import('../views/auth/login.vue') },
    { path: '/auth/old-login', component: () => import('../views/auth/oldLogin.vue') },
    
    {
        path: '/',  component: () => import('@/views/index.vue'),
        
        children: [
            { path: '/', component: () => import('@/views/home') },

            // My - 个人中心
            { meta: { keepAlive: true }, path: '/my/team',                      component: () => import('@/views/my/team') },
            { meta: { keepAlive: true }, path: '/my/projects',                  component: () => import('@/views/my/projects') },
            { meta: { keepAlive: true }, path: '/my/projectsTask',              component: () => import('@/views/my/projectsTask') },
            { meta: { keepAlive: true }, path: '/my/business',                  component: () => import('@/views/my/tableSaleDocumentary') },
            { meta: { keepAlive: true }, path: '/my/wo',                        component: () => import('@/views/my/wo') },
            { meta: { keepAlive: true }, path: '/my/contract',                  component: () => import('@/views/my/contract') },
            { meta: { keepAlive: true }, path: '/my/contract-pay-plan',         component: () => import('@/views/my/contractPayPlan') },
            { meta: { keepAlive: true }, path: '/my/contract-pay-enter',        component: () => import('@/views/my/contractPayEnter') },
            { meta: { keepAlive: true }, path: '/my/contract-pay-out',          component: () => import('@/views/my/contractPayOut') },
            { meta: { keepAlive: true }, path: '/my/contract-pay-invoice',      component: () => import('@/views/my/contractPayInvoice') },
            { meta: { keepAlive: true }, path: '/my/contract-pay-statistics',   component: () => import('@/views/my/contractPayStatistics') },
            { path: '/my/hrm-kpi-dossier',                    component: () => import('@/views/my/hrmKpiDossier') },

            // CRM
            { path: '/crm/statistics-pay-customer',                                 component: () => import('@/views/crm/statisticsPayCustomer') },
            { path: '/crm/statistics-pay-contract',                                 component: () => import('@/views/crm/statisticsPayContract') },
            { path: '/crm/contract',                                                component: () => import('@/views/crm/contract') },
            { path: '/crm/pay-enter',                                               component: () => import('@/views/crm/payEnter') },
            { path: '/crm/pay-out',                                                 component: () => import('@/views/crm/payOut') },
            { path: '/crm/pay-invoice',                                             component: () => import('@/views/crm/payInvoice') },
            { path: '/crm/pay-plan',                                                component: () => import('@/views/crm/payPlan') },
            { path: '/crm/product',                                                 component: () => import('@/views/ims/product') },
            { path: '/crm/visit',                                                   component: () => import('@/views/crm/visit') },
            { path: '/crm/contacts',                                                component: () => import('@/views/crm/contacts') },
            { path: '/crm/leads',                                                   component: () => import('@/views/crm/leads') },
            { path: '/crm/customer',                                                component: () => import('@/views/crm/customer') },
            { path: '/crm/business',                                                component: () => import('@/views/crm/business') },
            { path: '/crm/business-details',                                        component: () => import('@/views/crm/business') },
            { path: '/crm/business-task-details',                                   component: () => import('@/views/crm/business') },

            // HRM
            { path: '/hrm/organize',                    component: () => import('@/views/hrm/organize') },
            { path: '/hrm/employee',                    component: () => import('@/views/hrm/employee') },
            { path: '/hrm/login',                       component: () => import('@/views/hrm/login') },
            { path: '/hrm/kpi-goals',                   component: () => import('@/views/hrm/kpiGoals') },
            { path: '/hrm/kpi-template',                component: () => import('@/views/hrm/kpiTemplate') },
            { path: '/hrm/kpi-plan',                    component: () => import('@/views/hrm/kpiPlan') },
            { path: '/hrm/kpi-dossier',                    component: () => import('@/views/hrm/kpiDossier') },
            
            // OA
            { path: '/oa/news-table',       component: () => import('@/views/oa/newsTable') },
            { path: '/oa/news-chapter',     component: () => import('@/views/oa/newsTable') },
            { path: '/oa/ai-gpt',           component: () => import('@/views/oa/aiGpt/index') },
            { path: '/work/checkin-monitor',component: () => import('@/views/work/checkinMonitor') },
            
            // PM
            { meta: { keepAlive: true }, path: '/operate/projects',     component: () => import('@/views/pm/projects') },
            { path: '/pm/wo',                                           component: () => import('@/views/pm/wo') },
            { path: '/pm/task',                                         component: () => import('@/views/pm/task') },
            { path: '/pm/wo-alarm',                                     component: () => import('@/views/pm/woAlarm') },
            { path: '/pm/task-alarm',                                   component: () => import('@/views/pm/taskAlarm') },
            { path: '/pm/working-year',                          component: () => import('@/views/pm/workingYear') },
            { path: '/pm/working-month',                         component: () => import('@/views/pm/workingMonth') },

            // IMS
            { path: '/ims/supplier',                component: () => import('@/views/ims/supplier') },
            { path: '/ims/product',                 component: () => import('@/views/ims/product') },
            { path: '/ims/product-order',           component: () => import('@/views/ims/productOrder') },
            { path: '/ims/product-order-contract',  component: () => import('@/views/ims/productOrderContract') },
            { path: '/ims/product-order-company',   component: () => import('@/views/ims/productOrderCompany') },
            { path: '/ims/product-back',            component: () => import('@/views/ims/productBack') },
            { path: '/ims/product-invalid',         component: () => import('@/views/ims/productInvalid') },
            { path: '/ims/product-apply',           component: () => import('@/views/ims/productApply') },
            { path: '/ims/warehouse',               component: () => import('@/views/ims/warehouse') },
            { path: '/ims/warehouse-storage',       component: () => import('@/views/ims/warehouseStorage') },
            { path: '/ims/warehouse-document-enter',component: () => import('@/views/ims/warehouseDocumentEnter') },
            { path: '/ims/warehouse-document-back', component: () => import('@/views/ims/warehouseDocumentBack') },
            { path: '/ims/warehouse-move',          component: () => import('@/views/ims/warehouseMove') },
            { path: '/ims/warehouse-log',           component: () => import('@/views/ims/warehouseLog') },
            { path: '/ims/pay-enter',               component: () => import('@/views/ims/payEnter') },
            { path: '/ims/pay-out',                 component: () => import('@/views/ims/payOut') },

            // 配置中心
            { path: '/config/projects-type',                component: () => import('@/views/config/projectsType') },
            { path: '/config/projects-task-type',           component: () => import('@/views/config/projectsTaskType') },
            { path: '/config/projects-template',            component: () => import('@/views/config/projectsTemplate') },
            { path: '/config/users-team',                   component: () => import('@/views/config/usersTeam') },
            { path: '/config/users-role',                   component: () => import('@/views/config/usersRole') },
            { path: '/config/data-dict',                    component: () => import('@/views/config/dataDict') },
            { path: '/config/wo-template',                  component: () => import('@/views/config/woTemplate') },
            { path: '/config/workflow',                     component: () => import('@/views/config/workflow') },
            { path: '/config/data-import',                  component: () => import('@/views/config/dataImport') },
            { path: '/config/router-views',                 component: () => import('@/views/config/routerViews') },
            { path: '/config/invoice-type',                 component: () => import('@/views/config/invoiceType') },
            
        ]
    },

    
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 白名单
    const except  =  [
        '/auth/login',
        '/auth/old-login'
    ]

    // 校验Tocken
    const pathExcept = except.indexOf(to.path) != -1 ? true : false
    const pathTocken = sessionStorage.hasOwnProperty('token')

    // 重定向
    if (!pathTocken && !pathExcept) {
        sessionStorage.setItem('pathTo', to.fullPath)
        next('/auth/login')
    } else {
        next()
    }
})

export default router;

