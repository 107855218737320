<template>
    <el-dialog
        append-to-body
        destroy-on-close
        fullscreen
        class="el-dialog-menu"
        :visible.sync="visible"
        :close-on-click-modal="false" >

        <div class="el-dialog-menu-layout" v-loading="loading" :style="{ width: fullscreen ? '95%' : '80%', height: fullscreen ? '95%' : '80%' }">

            <!-- 窗口管理 -->
            <div class="el-dialog-menu-window">
                <i class="el-icon-warning-outline"></i>
                <i class="el-icon-full-screen" @click="fullscreen = !fullscreen"></i>
                <i class="el-icon-close" @click="visible = false"></i>
            </div>

            <!-- 菜单 -->
            <div class="el-dialog-menu-nav">

                <!-- 菜单 Header -->
                <div class="el-dialog-menu-nav-header">

                    <slot name="title">
                        <div class="el-dialog-menu-nav-header-title">{{ projects.number }}</div>
                        <div class="el-dialog-menu-nav-header-tip">{{ projects.name }}</div>
                    </slot>

                </div>

                <!-- 菜单 Item -->
                <div class="el-dialog-menu-nav-content">

                    <!-- 项目状态 -->
                    <div :style="{ marginBottom: '10px' }">
                        <el-alert center :closable="false" v-if="projects.status == 0" title="此项目暂未开始执行..." type="error" />
                        <el-alert center :closable="false" v-if="projects.status == 1" title="此项目正在进行中..." type="success" />
                        <el-alert center :closable="false" v-if="projects.status == 2" title="此项目已暂停..." type="warning" />
                        <el-alert center :closable="false" v-if="projects.status == 4" title="此项目关闭归档..." type="info" />
                        <el-alert center :closable="false" v-if="projects.status == 5" title="此项目正等待验收..." type="warning" />
                        <el-alert center :closable="false" v-if="projects.is_overdue" title="项目已经延期..." type="error" style="margin-top: 5px;" />
                    </div>

                    <!-- 功能按钮 -->
                    <div :style="{ marginBottom: '10px', display: 'grid', gap: '5px', gridTemplateColumns: 'repeat(2, 1fr)' }">
                        <el-button :style="{ margin: '0px' }" icon="el-icon-video-play" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 1)" :disabled="!(projects.status == 0 || projects.status == 2)">开始项目</el-button>
                        <el-button :style="{ margin: '0px' }" icon="el-icon-video-pause" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 2)" :disabled="projects.status == 2">暂停项目</el-button>
                        <el-button :style="{ margin: '0px' }" icon="el-icon-coordinate" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 5)">转待验收</el-button>
                        <el-button :style="{ margin: '0px' }" icon="el-icon-folder-checked" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 4)">关闭归档</el-button>
                    </div>

                    <!-- 项目菜单 -->
                    <div class="el-dialog-menu-nav-group">
                        <div class="el-dialog-menu-nav-group-title">默认</div>
                    
                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-base' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-base'">
                            <i class="el-icon-data-analysis"></i>
                            <span>项目概况</span>
                        </div>
                        
                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-describe' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-describe'">
                            <i class="el-icon-reading"></i>
                            <span>项目描述</span>
                        </div>

                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-milestone' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-milestone'">
                            <i class="el-icon-guide"></i>
                            <span>里程碑 / 项目阶段</span>
                        </div>

                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-task' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-task'">
                            <i class="el-icon-star-off"></i>
                            <span>任务管理</span>
                        </div>

                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-wo' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-wo'">
                            <i class="el-icon-phone-outline"></i>
                            <span>工单报障</span>
                        </div>

                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-team' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-team'">
                            <i class="el-icon-user"></i>
                            <span>团队管理</span>
                        </div>

                        <div :class="['el-dialog-menu-nav-item', currentComponent == 'pm-projects-drawer-files' ? 'is-active' : '' ]" @click="currentComponent = 'pm-projects-drawer-files'">
                            <i class="el-icon-folder-opened"></i>
                            <span>文档库</span>
                        </div>

                    </div>

                </div>

                <!-- 菜单 Item -->
                <div class="el-dialog-menu-nav-footer" :style="{ gridTemplateColumns: 'repeat(2, 1fr)' }">
                    <el-dialog-menu-item icon="el-icon-edit" title=" 编辑" @onClick="$refs.projectsUpdate.Open(projects.id)" />
                    <el-dialog-menu-item icon="el-icon-delete" title=" 删除" @onClick="DataDelete()" />
                </div>

            </div>

            <!-- 内容 -->
            <div class="el-dialog-menu-content">
                <component v-if="visible" :is="currentComponent" :projects_id="projects.id" :link_query="link_query" @onPage="OnPage" />
            </div>
        
        </div>

        <!-- 组件 -->
        <pm-projects-update ref='projectsUpdate' @success="RePage()" />
        <pm-projects-update-status ref="projectsUpdateStatus" @success="ProjectsGet()" />

    </el-dialog>
</template>

<script>

export default {

    // 存储数据
    data() {
        return {
            fullscreen: true,
            visible: false,
            loading: false,

            currentComponent: 'pm-projects-drawer-base',
            link_query: { },

            projects: {
                id: null,
                name: null,
                number: null
            },
        }
    },

    // 方法定义
    methods: {

        // 窗口 - 打开
        Open(id) {
            this.visible = true
            this.DataGet(id)
        },
        
        // 页面切换
        OnPage(data) {
            this.currentComponent = data.link_page
            this.link_query = data.link_query
        },

        // 窗口 - 关闭回调
        Close()
        {
            this.link_query = {}
        },

        // 查询数据
        DataGet(id = projects.id) {
            this.loading = true

            this.$http.post('/9api/projects/search-first', { id: id, hidden: ['describe'] }).then(rps => {
                this.projects = rps.data
                this.loading = false
            })
        },

        // 移除数据
        DataDelete() {
            this.$confirm('此操作将永久删除该项目信息，以及关联的文档、工时、工单、任务等一系列数据，请再三考虑！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/pm/projects/delete', { id: this.projects.id }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        }
    },
}

</script>

