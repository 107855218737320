<template>
    <el-card v-loading="loading">

        <!-- header -->
        <div slot="header">
            <span>
                <i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                成员消耗工时 / P
            </span>
        </div>

        <!-- Empty 提示 -->
        <el-empty v-if="!table.length" description="暂无工时记录"></el-empty>

        <!-- 数据表 -->
        <div v-if="table.length">
            <div v-for="(item, idx) in table" :key="idx" :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }">
                <div :style="{ display: 'flex', alignItems: 'center' }">
                    <el-avatar :style="{ marginRight: '5px' }" :size="26" v-if="item.users?.avatar" :src="item.users?.avatar">
                        {{ item.users?.name[0] }}
                    </el-avatar>
                    {{ item.users?.name }}
                </div>
                <el-link
                    :underline="false"
                    :style="{ fontSize: '12px',fontWeight: 'bold' }"
                    icon="el-icon-edit"
                    type="primary"
                    @click="update(item.id)" >
                    {{ item.day ?? 0 }}
                </el-link>
            </div>
        </div>

    </el-card>
</template>

<script>
export default {
    props: {
        task_id: {
            type: [Number, String],
            default: null
        }
    },

    data() {
        return {
            loading: false,
            
            table: [],
            post: {
                search: {
                    task_id: null
                },
                append: ['users']
            }
        }
    },

    watch: {
        'task_id': {
            handler(val) {
                if (val) {
                    this.post.search.task_id = val
                    this.search()
                } else {
                    this.post.search.task_id = null
                    this.table = []
                }
            },
            immediate: true
        }
    },

    methods: {

        // 查询
        search()
        {
            this.loading = true
            this.$http.post('/9api/pm/task-day/search-all', this.post).then(rps => {
                this.table = rps.data || []
                this.loading = false
            })
        },

        // 更新
        update(id)
        {
            this.$prompt('', '变更工时 / D', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\d+(\.\d+)?$/,
                inputErrorMessage: '格式不正确',
                inputPlaceholder: '请按小时输入，如：8.88'
            }).then(input => {
                this.$http.post('/9api/pm/task-day/update', { id: id, day: (input.value / 8).toFixed(2) }).then(rps => {
                    this.search()
                    this.$emit('onChange')
                })
            })
        }

    }
}
</script>