<template>
    <el-dialog
        :visible.sync="visible"
        :with-header="false"
        :before-close="close"
        title="工时记录"
        width="960px"
        direction="rtl"
        append-to-body>

        <!-- 切换标签 -->
        <el-tabs value="task">

            <!-- 任务 -->
            <el-tab-pane label="任务" name="task">
                
                <!-- 功能 -->
                <div :style="{ marginBottom: '10px' }">
                    <el-button type="primary" @click="$refs.xTableTask.openExport()">导出任务</el-button>
                    <el-button type="primary" @click="$refs.xTableTask.openPrint()">打印任务</el-button>
                </div>

                <!-- 数据 -->
                <vxe-table ref="xTableTask" :data="table.task" :loading="loading" :export-config="{}" :print-config="{}">

                    <!-- 关联项目 -->
                    <vxe-column min-width="150" title="关联项目">
                        <template #default="{ row }">
                            <el-span v-if="!row.projects" title="-" />
                            <el-span v-else
                                @onClick="$refs.projectsDrawer.Open(row.projects_id)"
                                type="primary"
                                :title="row.projects?.number + ' - ' + row.projects?.name" />
                        </template>
                    </vxe-column>

                    <!-- 任务名称 -->
                    <vxe-column min-width="150" title="任务名称">
                        <template #default="{ row }">
                            <el-span v-if="!row.task" title="任务已被移除" />
                            <el-span v-else
                                @onClick="$refs.taskDrawer.Open(row.task_id)"
                                type="primary"
                                :title="row.task?.name" />
                        </template>
                    </vxe-column>

                    <!-- 用户名称 -->
                    <vxe-column width="100" title="用户" align="right">
                        <template #default="{ row }">
                            {{ row.users?.name }}
                        </template>
                    </vxe-column>

                    <!-- 消耗工时 -->
                    <vxe-column width="100" title="消耗工时" align="right">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-time">{{ row.day }} / P</el-link>
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="60"  title="操作" align="center" fixed="right" v-if="$store.state.user.id == 12">
                        <template #default="{ row }">
                            <el-link :underline="false" @click.native.stop="$refs.taskAlarmCreate.Open({ task_id: row.task_id })" type="danger">异常</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>

            </el-tab-pane>

            <!-- 工单 -->
            <el-tab-pane label="工单">

                <!-- 功能 -->
                <div :style="{ marginBottom: '10px' }">
                    <el-button type="primary" @click="$refs.xTableWo.openExport()">导出工单</el-button>
                    <el-button type="primary" @click="$refs.xTableWo.openPrint()">打印工单</el-button>
                </div>

                <!-- 数据 -->
                <vxe-table ref="xTableWo" :data="table.wo" :loading="loading" :export-config="{}" :print-config="{}">
                    
                    <!-- 关联项目 -->
                    <vxe-column min-width="150" title="关联项目">
                        <template #default="{ row }">
                            <el-span v-if="!row.projects" title="-" />
                            <el-span v-else
                                @onClick="$refs.projectsDrawer.Open(row.projects_id)"
                                type="primary"
                                :title="row.projects?.number + ' - ' + row.projects?.name" />
                        </template>
                    </vxe-column>

                    <!-- 关联任务 -->
                    <vxe-column min-width="150" title="关联任务">
                        <template #default="{ row }">
                            <el-span v-if="!row.task" title="-" />
                            <el-span v-else
                                @onClick="$refs.taskDrawer.Open(row.task_id)"
                                type="primary"
                                :title="row.task?.name" />
                        </template>
                    </vxe-column>

                    <!-- 工单名称 -->
                    <vxe-column min-width="150" title="工单名称">
                        <template #default="{ row }">
                            <el-span v-if="!row.wo" title="任务已被移除" />
                            <el-span v-else
                                @onClick="$refs.woDrawer.Open(row.wo_id)"
                                type="primary"
                                :title="row.wo?.number + ' - ' + row.wo?.name" />
                        </template>
                    </vxe-column>

                    <!-- 用户名称 -->
                    <vxe-column width="100" title="用户" align="right">
                        <template #default="{ row }">
                            {{ row.users?.name }}
                        </template>
                    </vxe-column>

                    <!-- 消耗工时 -->
                    <vxe-column width="100" title="消耗工时" align="right">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-time">{{ row.day }} / P</el-link>
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="60"  title="操作" align="center" fixed="right" v-if="$store.state.user.id == 12">
                        <template #default="{ row }">
                            <el-link :underline="false" @click.native.stop="$refs.woAlarmCreate.Open({ wo_id: row.wo_id })" type="danger">异常</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>

            </el-tab-pane>

        </el-tabs>

        <!-- 组件 -->
        <pm-projects-drawer ref="projectsDrawer" @onchange="search()"  />
        <pm-task-alarm-create ref="taskAlarmCreate" @onChange="search()" />
        <pm-task-drawer ref="taskDrawer" @onchange="search()"  />
        <pm-wo-alarm-create ref="woAlarmCreate" @onChange="search()" />
        <pm-wo-drawer ref="woDrawer" @onchange="search()" />

    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            table: {
                task: [],
                wo: []
            },

            post: {
                search: {
                    users_id: null,
                    year: null,
                    month: null,
                    projects_id: null
                }
            }
        }
    },

    methods: {

        // 窗口：打开
        open(config)
        {
            this.visible = true
            this.post.search = { ...this.post.search, ...config }
            this.search()
        },

        // 窗口：关闭
        close()
        {
            this.table.task = []
            this.table.wo = []
            this.loading = false
            this.visible = false
        },

        // 查询
        search()
        {
            this.loading = true
            this.$http.post('/9api/pm/working/search-detailed', this.post.search).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        }

    }
}
</script>