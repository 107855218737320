<template>
    <el-card v-loading="loading">
        <div slot="header" class="clearfix">
            <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>PM P值排行榜</span>
            <div>
                <el-dropdown @command="ChangeActive" :style="{ marginRight: '5px' }">
                    <el-button type="primary">
                        {{ active == 'sum_day_consume' ? '总P值' : (active == 'task_day_consume' ? '任务P值' : '工单P值') }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="sum_day_consume">总P值</el-dropdown-item>
                        <el-dropdown-item command="task_day_consume">任务P值</el-dropdown-item>
                        <el-dropdown-item command="wo_day_consume">工单P值</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <uw-date-range @onChange="UpdateDataRang" :show-custom="false" />
            </div>
        </div>

        <div v-for="(item, idx) in working.slice(0, 10)" :key="idx" :style="{ color: idx < 3 ? '#0027ff' : '#9e9e9e' }">
            <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', paddingBottom: '10px' }">
                <div :style="{ fontStyle: 'italic', display: 'flex', alignItems: 'center'}">
                    No.<b :style="{ fontSize: '14px' }">{{ idx + 1 }}</b>
                </div>
                <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
                    <div :style="{ width: '80px', textAlign: 'right' , fontSize: '12px', fontWeight: idx < 3 ? 'bold' : 'normal' }">
                        {{ item[active]?.toFixed(2) ?? 0 }}
                    </div>
                </div>
                <div>
                    <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', float: 'right' }">
                        {{ item.users?.name }}
                        <el-avatar :style="{ marginLeft: '5px' }" :size="26" v-if="item.users?.avatar" :src="item.users?.avatar">
                            {{ item.users?.name[0] }}
                        </el-avatar>
                    </div>
                </div>
            </div>
        </div>

    </el-card>
</template>

<script>
export default {
    data() {
        return {
            working: [],
            ranking: [],
            active: 'sum_day_consume',

            search: {
                date_range: [
                    this.$lodash.date_range('month')[0],
                    this.$lodash.date_range('month')[1]
                ]
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    watch: {
        search: {
            handler() {
                this.Update()
            },
            deep:true,
            immediate: true
        }
    },

    methods: {
        Update() {
            this.$http.post('/9api/pm/working/search-dateRange', this.search ).then(rps => {
                this.working = rps.data
                this.ChangeActive(this.active)
            })
        },

        ChangeActive (command)
        {
            this.active = command
            this.ranking = this.working.sort((a, b) => b[command] - a[command])
            
            for (let index = 10; this.ranking.length < index; index) {
                this.ranking.push({
                    users: {
                        id: null,
                        name: "未定",
                        phone: "99999999999",
                        avatar: "/img/illustration/3.png"
                    },
                    task_day_consume: 0,
                    wo_day_consume: 0,
                    sum_day_consume: 0
                })
            }
        },

        UpdateDataRang({ date_start, date_end }) {
            this.search.date_range = [date_start, date_end]
        },
    }
}
</script>