<template>
    <el-card :style="{ marginBottom: '20px' }">
        <div slot="header">
            <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                流程记录
            </span>
        </div>

        <!-- Empty -->
        <el-empty description="暂无记录" v-if="!table.length"></el-empty>

        <el-collapse v-model="activeName" accordion>
            <el-collapse-item v-for="(item, idx) in table" :key="idx" :name="idx">
                <template slot="title">
                    {{ item.stage + ' ' + item.create_user?.name + ' 于 ' + item.created_at }}
                </template>
                <froala-views v-model="item.describe" />
            </el-collapse-item>
        </el-collapse>

    </el-card>
</template>

<script>
export default {
    props: {
        task_id: {
            type: [Number, String],
            default: null
        }
    },

    data() {
        return {
            activeName: 0,

            table: []
        }
    },

    watch: {
        'task_id': {
            handler(val) {
                val ? this.search() : this.table = []
            },
            immediate: true
        }
    },

    methods: {
        
        // 查询
        search()
        {
            this.loading = true
            this.$http.post('/9api/projects-flow/search-task', { id: this.task_id, append: ['user', 'create_user', 'update_user'] }).then(rps => {
                this.table = rps.data || []
                this.loading = false
            })
        }

    }
}
</script>