<template>
    <el-card>
        <div slot="header">
            <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>任务概况</span>
        </div>
        <froala-views v-model="value" />
    </el-card>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },
    }
}
</script>