<template>
    <el-dialog :visible.sync="visible" width="960px" append-to-body :close-on-click-modal="false">

        <!-- Title -->
        <template slot="title">
            任务完成 - {{ task.name }}
        </template>
        
        <div :style="{ backgroundColor: '#F5F7FA', borderRadius: '3px', padding: '15px 15px 5px 15px' }">

            <!-- 基本信息 -->
            <div :style="{ display: 'flex' }">

                <!-- 截止时间 -->
                <el-statistic title="截止时间">
                    <template slot="formatter">
                        <span style="font-size: 16px;"><b>{{ task.start_date[1] }}</b></span>
                    </template>
                </el-statistic>

                <!-- 预估工时/P -->
                <el-statistic title="预估工时/P">
                    <template slot="formatter">
                        <span style="font-size: 14px;">{{ task.day_predict }}</span>
                    </template>
                </el-statistic>

                <!-- 已用工时/P -->
                <el-statistic title="已用工时/P">
                    <template slot="formatter">
                        <span style="font-size: 14px;">{{ task.day_consume }}</span>
                    </template>
                </el-statistic>
                
                <!-- 可用工时/P -->
                <el-statistic title="可用工时/P">
                    <template slot="formatter">
                        <span style="font-size: 14px;">
                            {{ task.day_predict - task.day_consume }}
                        </span>
                    </template>
                </el-statistic>

            </div>

            <!-- 步骤栏 -->
            <el-steps :active="active" simple :style="{ marginTop: '20px', borderTop: '1px solid #eeeeee' }">
                <el-step title="1. 工时记录" icon="el-icon-tickets"></el-step>
                <el-step title="2. 完成描述" icon="el-icon-collection"></el-step>
                <el-step title="3. 上传附件" icon="el-icon-folder-opened"></el-step>
            </el-steps>

        </div>
        
        <!-- 1. 工时记录 -->
        <div :style="{ margin: '20px 0', height: '450px', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }" v-show="active == 0">

            <!-- 插画 -->
            <el-image src="/img/illustration/1.png" :style="{ width: '40%' }"></el-image>

            <!-- 表单 -->
            <el-form :model="form" label-position="top" :style="{ width: '40%' }">

                <!-- 完成日期 -->
                <el-form-item label="完成日期">
                    <el-date-picker v-model="form.finish_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择完成时间" :style="{ width: '100%' }" />
                </el-form-item>

                <!-- 消耗工时 -->
                <el-form-item label="消耗工时 / D">
                    <el-input v-for="(item, idx) in form.users" :key="idx" v-model="item.consume_hour" @focus="focusFormUsers()" @blur="blurFormUsers()" :style="{ marginBottom: '10px' }" >
                        <template slot="prepend">
                            <span :style="{ width: '40px', display: 'block' }">{{ item.user_name }}</span>
                        </template>
                        <template slot="append">
                            小时
                        </template>
                    </el-input>
                </el-form-item>

            </el-form>

        </div>

        <!-- 2. 完成描述 -->
        <div :style="{ margin: '20px 0', minHeight: '450px' }" v-show="active == 1">
            <froala-editor v-model="form.describe" clean :height="360" :heightMax="360" v-if="active == 1 && visible" />
        </div>

        <!-- 3. 上传附件 -->
        <div :style="{ margin: '20px 0', height: '450px', overflow: 'auto' }" v-show="active == 2">
            <files-upload v-model="form.files_id" model="projects_task" stage="任务完成" />
        </div>

        <!-- footer -->
        <div slot="footer">
            <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                <div>
                    <el-button type="primary" @click="active -= 1" :disabled="!active">上一步</el-button>
                    <el-button type="primary" @click="active += 1" :disabled="active == 2">下一步</el-button>
                </div>
                <div>
                    <el-button size="mini" @click="Close()">取消</el-button>
                    <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading" v-if="!formState">确 定</el-button>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>

export default {
    data() {
        return {
            // 窗口
            visible: false,
            loading: false,
            active: 0,


            // 任务信息
            task: {
                start_date: []
            },
            team: [],

            // 表单信息
            formState: false,
            formError: {},
            form: {
                describe: null,
                users: [],
                files_id: [],
                finish_at: this.$lodash.date_time(),
            },
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(row) {
            
            // 加载内容
            new Promise((resolve) => {
                this.task = row
                this.loading = true
                this.visible = true
                this.form.users = []
                resolve()
            }).then(() => {
                // 初始化参与人员表单
                this.form.assign = this.task.assign
                this.form.now_assign = this.task.now_assign

                // 添加工时消耗表单
                this.task.assign_user_object.forEach(element => {
                    this.form.users.push({
                        user_id: element.id,
                        user_name: element.name,
                        consume_days: 0,
                        consume_hour: 0,
                    })
                })

                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.formState = false
            this.formError = []

            this.form.describe = null
            this.form.users = []
            this.form.files_id = []
            
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 任务完成
        |--------------------------------------------------
        |
        */

        SubmitForm() {

            this.loading = true
            this.$http.post('/9api/projects-task/flow-complete', { id: this.task.id, form: this.form }).then(rps => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                    this.loading = false
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.task = rps.data.body
                    this.$emit('onSave', rps.data.body)
                    this.formState = true
                    this.loading = false
                    this.Close()
                }
            })
        },

        // 工时换算：获得焦点时触发
        focusFormUsers()
        {
            this.loading = true
        },

        // 工时换算：失去焦点时触发
        blurFormUsers()
        {
            this.form.users.forEach(element => {
                element.consume_hour = (Number(element.consume_hour) || 0).toFixed(2)
                element.consume_days = (element.consume_hour / 8).toFixed(2)
            })
            this.loading = false
        }
    }
}
</script>