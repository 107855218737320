<template>
    <el-dialog
        :visible.sync="visible"
        :close-on-click-modal="false"
        title="编辑任务"
        width="900px"
        append-to-body
        destroy-on-close
        @close="Close" >

        <el-steps :active="active" simple>
            <el-step title="1. 任务信息" icon="el-icon-tickets"></el-step>
            <el-step title="2. 具体描述" icon="el-icon-collection"></el-step>
            <el-step title="3. 上传附件" icon="el-icon-folder-opened"></el-step>
        </el-steps>

        <!-- 1. 任务信息 -->
        <div :style="{ margin: '20px 0', height: '550px', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }" v-show="active == 0">

            <!-- 插画 -->
            <el-image src="/img/illustration/1.png" :style="{ width: '40%' }"></el-image>

            <!-- 表单 -->
            <el-form :model="form" label-position="top" :style="{ width: '40%' }">

                <!-- 里程碑 / 项目阶段 -->
                <el-form-item label="里程碑 / 项目阶段">
                    <pm-milestone-select v-model="form.milestone_id" :projects_id="form.project_id" />
                </el-form-item>

                <!-- 任务名称 -->
                <el-form-item label="任务名称" :rules="[{ required: true, message: '请填写任务名称, 最多填入64个字符' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" style="float:left;" >
                        <el-color-picker slot="prepend" v-model="form.name_color" show-alpha :predefine="color"></el-color-picker>
                    </el-input>
                </el-form-item>

                <!-- 任务类型 -->
                <el-form-item label="任务类型" :rules="[{ required: true, message: '请选择任务类型' }]" :error="formError.type_id?.toString()" prop="type_id">
                    <projects-task-type-select v-model="form.type_id" width="350px" @onChange="(rps) => form.day_predict = rps.working_days" />
                </el-form-item>

                <!-- 重要程度/优先级 -->
                <el-form-item label="重要程度 / 优先级" :rules="[{ required: true, message: '请选择任务的重要程度或优先级' }]" :error="formError.p?.toString()" prop="p">
                    <projects-task-select-p v-model="form.p" clearable filterable width="350px" />
                </el-form-item>

                <!-- 指派给 -->
                <el-form-item label="指派给" :rules="[{ required: true, message: '请选择任务指派人' }]" :error="formError.now_assign?.toString()" prop="now_assign">
                    <inputTeam v-model="form.now_assign" :project_id="form.project_id" clearable filterable width="350px"></inputTeam>
                </el-form-item>

                <!-- 参与成员 / 协助成员 -->
                <el-form-item label="参与成员 / 协助成员">
                    <inputTeam v-model="form.assign" :project_id="form.project_id" clearable filterable multiple width="350px"></inputTeam>
                </el-form-item>
                
                <!-- 起止日期 -->
                <el-form-item label="起止日期" :rules="[{ required: true, message: '请选择任务起止日期' }]" :error="formError.start_date?.toString()" prop="start_date">
                    <el-date-picker v-model="form.start_date" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>

                <!-- 预估工时 -->
                <el-form-item label="预估工时 / P">
                    <el-input-number v-model="form.day_predict" :min="0" label="预估工时" :style="{ width: '100%' }" />
                </el-form-item>

            </el-form>

        </div>

        <!-- 2. 具体描述 -->
        <div :style="{ margin: '20px 0', minHeight: '550px' }" v-show="active == 1">
            <froala-editor v-model="form.describe" clean :height="460" :heightMax="460" v-if="active == 1 && visible" />
        </div>

        <!-- 3. 上传附件 -->
        <div :style="{ margin: '20px 0', height: '550px', overflow: 'auto' }" v-show="active == 2">
            <files-upload v-model="form.files_id" model="projects_task" stage="更新任务" />
        </div>

        <!-- footer -->
        <div slot="footer">
            <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                <div>
                    <el-button type="primary" @click="active -= 1" :disabled="!active">上一步</el-button>
                    <el-button type="primary" @click="active += 1" :disabled="active == 2">下一步</el-button>
                </div>
                <div>
                    <el-button @click="Close()">取消</el-button>
                    <el-button type="primary" @click="SubmitForm()" :disabled="loading">确定修改</el-button>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>
import inputTeam from '@/views/projects/inputProjectsTeam'

export default {
    components: {
        inputTeam,
    },

    data () {
        return {
            // 颜色选择器
            color: [
                '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', 
                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', 
                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', 
                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000'
            ],

            // 加载状态
            visible: false,
            loading: false,
            active: 0,

            // 表单
            formError: {},
            form: {
                id: null,
                milestone_id: null,
                project_id: null,
                parent: null,
                name: null,
                name_color: '#5489EA',
                p: 2,
                type_id: null,
                assign: [],
                now_assign: null,
                start_date: [],
                day_predict: 0,
                describe: null,
                files_id: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open (id) {
            this.visible = true
            this.$http.post('/9api/projects-task/search-id', { id: id }).then(rps => {
                this.form = rps.data
            })
        },
        

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.ReForm()
            this.loading = false
            this.visible = false
        },
        

        /**
        |--------------------------------------------------
        | 编辑任务
        |--------------------------------------------------
        |
        */

        SubmitForm () {
            this.loading = true

            this.$http.post('/9api/projects-task/edit', this.form).then(rps => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.loading = false
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onSave', rps.data.body)
                    this.loading = false
                    this.visible = false
                }
                
            })
        },

        /**
        |--------------------------------------------------
        | 重置表单
        |--------------------------------------------------
        |
        */

        ReForm() {
            this.formError = {}

            this.form.id = null
            this.form.milestone_id = null
            this.form.project_id = null
            this.form.parent = null
            this.form.name = null
            this.form.name_color = '#5489EA'
            this.form.p = 2
            this.form.type_id = null
            this.form.assign = []
            this.form.now_assign = null
            this.form.start_date = []
            this.form.day_predict = 0
            this.form.describe = null
            this.form.files_id = []
        }
    }
}
</script>