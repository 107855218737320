<template>
    <el-card>

        <!-- Header -->
        <div slot="header">
            <span>
                <i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                关联工单
            </span>
            <el-button type="primary" @click="$refs.woCreate.Open({ projects_id: projects_id, projects_task_id: task_id })">生成工单</el-button>
        </div>

        <!-- Empty -->
        <el-empty description="暂无工单" v-if="!table.length"></el-empty>

        <div v-for="(item, idx) in table" :key="idx">
            <div :style="{ marginBottom: '10px', padding: '10px', background: '#F5F5F5', borderRadius: '5px' }">
                <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                    <el-span :title="item.template?.name + '-' + item.name" type="primary" @onClick="$refs.drawerDetails.Open(item.id)" />
                    <div>
                        <el-tag :style="{ marginLeft: '5px' }" v-if="item.p == 1" effect="plain" type="danger">P1 非常重要</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" v-if="item.p == 2" effect="plain" type="warning">P2 重要</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" v-if="item.p == 3" effect="plain" type="primary">P3 次要</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" v-if="item.p == 4" effect="plain" type="success">P4 一般</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="warning" v-if="item.state == 0">待确认</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" v-if="item.state == 1">进行中</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="success" v-if="item.state == 2">已完成</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="info" v-if="item.state == 3">已关闭</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="warning" v-if="item.state == 4">已暂停</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="warning" v-if="item.state == 5">已取消</el-tag>
                        <el-tag :style="{ marginLeft: '5px' }" effect="dark" type="danger" v-if="item.is_overdue">已逾期</el-tag>
                    </div>
                </div>
                <div :style="{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }">
                    <div>
                        <el-link :underline="false" icon="el-icon-service">{{  item.user_receive?.name }}</el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link :underline="false" icon="el-icon-phone-outline">{{  item.user_sending?.name }}</el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link :underline="false" icon="el-icon-date">{{ item.range_at_start }} 至 {{ item.range_at_end }}</el-link>
                    </div>
                    <div>
                        <el-link :underline="false"><strong>预估：</strong></el-link>
                        <el-link :underline="false">{{ item.day_predict }} / P</el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link :underline="false"><strong>消耗：</strong></el-link>
                        <el-link :underline="false">{{ item.day_consume }} / P</el-link>
                        <el-divider direction="vertical"></el-divider>
                        <el-link :underline="false"><strong>剩余：</strong></el-link>
                        <el-link :underline="false">{{ item.day_surplus }} / P</el-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- 组件 -->
        <pm-wo-drawer ref="drawerDetails" @onChange="search" />
        <pm-wo-create ref="woCreate" @onChange="search" />

    </el-card>
</template>

<script>
export default {
    props: ['projects_id', 'task_id'],

    data() {
        return {
            // 窗口
            loading: false,

            table: []
        }
    },

    watch: {
        'task_id': {
            handler(val) {
                val ? this.search() : this.table = []
            },
            immediate: true
        }
    },

    methods: {

        // 查询
        search() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/pm/wo/search-all',
                {
                    search: { projects_id: this.projects_id , projects_task_id: this.task_id },
                    append: ['user_receive', 'user_sending', 'template'],
                    hidden: ['describe']
                }
            ).then(rps => {
                this.table = rps.data || []
                this.loading = false
            })
        }
    }
}
</script>