<!---------------------------------------------------------------------------- 
 | 项目管理 - 创建项目
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open({ contract_id }) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 创建项目
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="projects" label-width="120px" size="mini">
            
                <!-- 关联合同 -->
                <el-form-item label="关联合同">
                    <crm-contract-select v-model="projects.contract_id" @onChange="ContractOnChange" />
                </el-form-item>

                <!-- 项目名称 -->
                <el-form-item label="项目名称" :rules="[{ required: true, message: '请填写项目名称' }]" :error="projectsError.name?.toString()" prop="name">
                    <el-input v-model="projects.name" placeholder="X省X市XXxx项目" clearable />
                </el-form-item>

                <!-- 项目名称 -->
                <el-form-item label="项目编号" :rules="[{ required: true, message: '请填写项目编号' }]" :error="projectsError.number?.toString()" prop="number">
                    <el-input v-model="projects.number" placeholder="CXXXXXX" clearable />
                </el-form-item>

                <!-- 项目类型 -->
                <el-form-item label="项目类型" :rules="[{ required: true, message: '请选择项目类型' }]" :error="projectsError.type?.toString()" prop="type">
                    <projects-type-select v-model="projects.type" clearable filterable multiple width="350px" />
                </el-form-item>

                <!-- 项目周期 -->
                <el-form-item label="项目周期" :rules="[{ required: true, message: '请选择项目周期' }]" :error="projectsError.start_date?.toString()" prop="start_date">
                    <el-date-picker
                        @change="StartSateChange()"
                        v-model="projects.start_date"
                        type="daterange"
                        align="right"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <!-- 项目经理 -->
                <el-form-item label="项目经理">
                    <users-select v-model="projects.leader" />
                </el-form-item>

                <!-- 项目描述 -->
                <el-form-item label="项目描述" >
                    <froala-editor v-model="projects.describe" />
                </el-form-item>

                <!-- 访问控制 -->
                <el-form-item label="访问控制" :error="projectsError.jurisdiction?.toString()" prop="jurisdiction">
                    <el-radio-group v-model="projects.jurisdiction">
                        <el-tooltip effect="dark" content="有目代视图权限，即可访问" placement="top-start">
                            <el-radio :label="1">默认设置</el-radio>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="只有项目团队成员才能访问" placement="top-start">
                            <el-radio :label="2">私有项目</el-radio>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="团队成员和白名单的成员可以访问" placement="top-start">
                            <el-radio :label="3">自定义白名单</el-radio>
                        </el-tooltip>
                    </el-radio-group>
                </el-form-item>

                <!-- 访问控制 - 访问白名单 -->
                <el-form-item label="访问白名单" v-if="projects.jurisdiction == 3">
                    <config-role-select v-model="projects.jurisdiction_role" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="ProjectsCreate()" :loading="loading">创建项目</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            projectsError: {},
            projects: {
                name: null,
                number: null,
                leader: null,
                contract_id: null,
                type: null,
                start_date: null,
                describe: null,
                jurisdiction: 1,
                jurisdiction_role: []
            },
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        open(config) {
            // 默认合同信息配置
            if (config) this.projects.contract_id = config.contract_id

            // 窗口配置
            this.visible = true
            this.loading = false
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 合同信息 - 映射表单
        |--------------------------------------------------
        |
        */
        
        ContractOnChange(data) {
            this.projects.name = data?.name
            this.projects.number = data?.number
            this.projects.start_date = data?.time_limit
        },

        /**
         * 项目周期 - 选择事件
         */
        StartSateChange() {
            const start_date = new Date(this.projects.start_date[0])
            const end_date = new Date(this.projects.start_date[1])
            this.projects.working_days = ((end_date.getTime() - start_date.getTime()) / 86400 / 1000) + 1
        },

        /**
         * 创建项目 - 表单提交
         */
        ProjectsCreate() {
            // 清空错误提示
            this.loading = true
            this.projectsError = {}

            this.$http.post('/9api/projects/create', this.projects).then(rps => {
                
                this.loading = false

                if (rps.data.status == 'error') {
                    this.projectsError = rps.data.error
                }

                if (rps.data.status == 'success') {
                    this.$notify.success({
                        title: '提示',
                        message: rps.data.success,
                        duration: 2000
                    })
                    this.$emit("success")
                    this.Close()
                }
            })
        }
    }
}
</script>